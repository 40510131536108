<template>
    <div id="dc-dashboard">
        <h3>Dashboard</h3>
        <hr />

        <!-- Loading Spinner -->
        <div v-if="ticketsList.length == 0" class="dc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>
        <div v-else id="dc-report-container">
            <!-- <BarChart /> -->

            <div id="dc-report-product-row-1">
                <div class="dc-report-product">
                    <h3>Soil</h3>
                    <hr />
                    <div class="dc-daily">
                        <span>Today: </span><span class="dc-total-value">{{ parseInt(this.soil.daily) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Weekly: </span><span class="dc-total-value"> {{ parseInt(this.soil.weekly) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Monthly: </span><span class="dc-total-value"> {{ parseInt(this.soil.monthly) }}</span>
                    </div>
                    <!-- <div class="dc-monthly">
                        <span>Total: </span
                        ><span class="dc-total-value">
                            {{ this.soil.total }}
                        </span>
                    </div> -->
                </div>
                <div class="dc-report-product">
                    <h3>Aggregate</h3>
                    <hr />
                    <div class="dc-daily">
                        <span>Today: </span><span class="dc-total-value"> {{ parseInt(this.aggregate.daily) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Weekly: </span><span class="dc-total-value">{{ parseInt(this.aggregate.weekly) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Monthly: </span><span class="dc-total-value"> {{ parseInt(this.aggregate.monthly) }}</span>
                    </div>
                    <!-- <div class="dc-monthly">
                        <span>Total: </span
                        ><span class="dc-total-value">
                            {{ this.aggregate.total }}
                        </span>
                    </div> -->
                </div>

                <div class="dc-report-product">
                    <h3>Mulch</h3>
                    <hr />
                    <div class="dc-daily">
                        <span>Today: </span><span class="dc-total-value">{{ parseInt(this.mulch.daily) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Weekly: </span><span class="dc-total-value">{{ parseInt(this.mulch.weekly) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Monthly: </span><span class="dc-total-value">{{ parseInt(this.mulch.monthly) }}</span>
                    </div>

                    <!-- <div class="dc-monthly">
                        <span>Total: </span
                        ><span class="dc-total-value">
                            {{ this.mulch.total }}
                        </span>
                    </div> -->
                </div>
            </div>

            <div id="dc-report-product-row-2">
                <div class="dc-report-product">
                    <h3>Grass Seed</h3>
                    <hr />
                    <div class="dc-daily">
                        <span>Today: </span><span class="dc-total-value">{{ parseInt(this.grassSeed.daily) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Weekly: </span><span class="dc-total-value">{{ parseInt(this.grassSeed.weekly) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Monthly: </span><span class="dc-total-value">{{ parseInt(this.grassSeed.monthly) }}</span>
                    </div>

                    <!-- <div class="dc-monthly">
                        <span>Total: </span
                        ><span class="dc-total-value">
                            {{ this.grassSeed.total }}
                        </span>
                    </div> -->
                </div>

                <div class="dc-report-product">
                    <h3>COD</h3>
                    <hr />
                    <div class="dc-daily">
                        <span>Today: </span><span class="dc-total-value">{{ parseInt(this.cod.daily) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Weekly: </span><span class="dc-total-value">{{ parseInt(this.cod.weekly) }}</span>
                    </div>
                    <div class="dc-weekly">
                        <span>Monthly: </span><span class="dc-total-value">{{ parseInt(this.cod.monthly) }}</span>
                    </div>

                    <!-- <div class="dc-monthly">
                        <span>Total: </span
                        ><span class="dc-total-value">
                            {{ this.cod.total }}
                        </span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./../ticket/APICalls";
import util from "./../../util/util";

export default {
    name: "Dashboard",
    data() {
        return {
            ticketsList: [],
            soil: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            aggregate: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            mulch: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            grassSeed: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
            cod: {
                total: 0,
                daily: 0,
                weekly: 0,
                monthly: 0,
            },
        };
    },
    computed: {
        // ...mapState("ticket", ["tickets"]),
    },
    methods: {
        // ...mapActions("ticket", ["setTickets"]),
        setDashboard() {
            _.each(this.ticketsList, ticket => {
                let lineitems = ticket.lineitems;
                let createdAt = _.get(ticket, "createdAt");
                if (lineitems) {
                    _.each(lineitems, item => {
                        let itemType = _.get(item, "item.itemType.name", "");
                        let quantity = _.get(item, "quantity", 0);
                        this.setCategoryValues(itemType, quantity, createdAt);
                    });
                }
            });
        },
        setCategoryValues(itemType, quantity, createdAt) {
            let pastDay = parseInt(util.getTicketPastDay(createdAt));
            switch (itemType) {
                case "Soil":
                    this.setSoil(quantity, pastDay);
                    break;

                case "Aggregate":
                    this.setAggregate(quantity, pastDay);
                    break;

                case "Mulch":
                    this.setMulch(quantity, pastDay);
                    break;

                case "Grass Seed":
                    this.setGrassSeed(quantity, pastDay);
                    break;

                case "COD":
                    this.setCod(quantity, pastDay);
                    break;
            }
        },
        setSoil(quantity, pastDay) {
            this.soil.total += quantity;
            if (pastDay <= 0) {
                this.soil.daily += quantity;
            }

            if (pastDay <= 7) {
                this.soil.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.soil.monthly += quantity;
            }
        },
        setAggregate(quantity, pastDay) {
            this.aggregate.total += quantity;
            if (pastDay <= 0) {
                this.aggregate.daily += quantity;
            }

            if (pastDay <= 7) {
                this.aggregate.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.aggregate.monthly += quantity;
            }
        },
        setMulch(quantity, pastDay) {
            this.mulch.total += quantity;
            if (pastDay <= 0) {
                this.mulch.daily += quantity;
            }

            if (pastDay <= 7) {
                this.mulch.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.mulch.monthly += quantity;
            }
        },
        setGrassSeed(quantity, pastDay) {
            this.grassSeed.total += quantity;
            if (pastDay <= 0) {
                this.grassSeed.daily += quantity;
            }

            if (pastDay <= 7) {
                this.grassSeed.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.grassSeed.monthly += quantity;
            }
        },
        setCod(quantity, pastDay) {
            this.cod.total += quantity;
            if (pastDay <= 0) {
                this.cod.daily += quantity;
            }

            if (pastDay <= 7) {
                this.cod.weekly += quantity;
            }

            if (pastDay <= 30) {
                this.cod.monthly += quantity;
            }
        },
        async getTickets() {
            try {
                let tickets = await APICalls.getTickets();
                this.ticketsList = tickets;
                // this.setTickets(tickets);
            } catch (e) {
                console.log(e);
                this.$router.push({ path: "/login" });
            }
        },
    },
    mounted() {
        this.getTickets()
            .then(() => {
                this.setDashboard();
            })
            .catch(err => {
                console.log(err);
            });
    },
};
</script>

<style lang="scss">
#dc-report-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #dc-report-product-row-1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
    }

    #dc-report-product-row-2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
    }

    .dc-report-product {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid black;
        border-radius: 5px;
        width: 30%;
        height: 300px;
        padding: 10px;

        hr {
            background-color: #9eab05;
            width: 100%;
            height: 2px;
        }

        .dc-daily,
        .dc-weekly,
        .dc-monthly {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 10px;

            span:nth-child(1) {
                font-size: 20px;
                width: 50%;
                text-align: left;
            }

            span:nth-child(2) {
                font-size: 20px;
                font-weight: bold;
                width: 20%;
                text-align: left;
            }
        }
    }
}
</style>
